import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Img from "gatsby-image";

import Layout from "components/Layout";
import SEO from "components/Seo";
import Card from "components/Card";
import { IntlProvider } from "contexts/intl/IntlContext";
import { externalLink } from "constants/paths";

const queryImage = graphql`
  query {
    space_war_logo: file(relativePath: { eq: "space-war-logo.jpg" }) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    make_note_logo: file(relativePath: { eq: "make-note-logo.png" }) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    deal_spree_logo: file(relativePath: { eq: "deal-spree-logo.jpg" }) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Projects = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: "projects.title" })}
          description={formatMessage({ id: "meta.description" })}
          keywords={formatMessage({ id: "meta.keywords" })}
        />
        <section className="section is-size-4-desktop is-size-5-touch">
          <h1 className="title has-text-centered has-text-light">
            <FormattedMessage id="projects.title" />
          </h1>
          <div className="container">
            <hr />
            <div className="columns">
              <div className="column is-half is-offset-one-quarter">
                <StaticQuery
                  query={queryImage}
                  render={(data) => (
                    <>
                      <Card
                        title={formatMessage({
                          id: "projects.deal_spree.title",
                        })}
                        subtitle={formatMessage({
                          id: "projects.deal_spree.subtitle",
                        })}
                        link={externalLink.deal_spree}
                        tags={["Android", "Java"]}
                        image={
                          <Img
                            fixed={data.deal_spree_logo.childImageSharp.fixed}
                            alt={formatMessage({
                              id: "projects.deal_spree.imageAlt",
                            })}
                          />
                        }
                      />

                      <Card
                        title={formatMessage({
                          id: "projects.space_war.title",
                        })}
                        subtitle={formatMessage({
                          id: "projects.space_war.subtitle",
                        })}
                        link={externalLink.space_war}
                        tags={["Android", "cocos2d-x", "C++"]}
                        image={
                          <Img
                            fixed={data.space_war_logo.childImageSharp.fixed}
                            alt={formatMessage({
                              id: "projects.space_war.imageAlt",
                            })}
                          />
                        }
                      />

                      <Card
                        title={formatMessage({
                          id: "projects.make_note.title",
                        })}
                        subtitle={formatMessage({
                          id: "projects.make_note.subtitle",
                        })}
                        link={externalLink.make_note}
                        tags={[".NET", "Windows Desktop"]}
                        image={
                          <Img
                            fixed={data.make_note_logo.childImageSharp.fixed}
                            alt={formatMessage({
                              id: "projects.make_note.imageAlt",
                            })}
                          />
                        }
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <p className="has-text-centered">
              <FormattedMessage id="checkOut" />{" "}
              <a
                href={externalLink.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
              {" for more."}
            </p>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
);

Projects.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Projects;
